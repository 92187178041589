<template>
  <v-dialog
    v-model="show"
    transition="slide-y-reverse-transition"
    persistent
    overlay-opacity=".9"
    style="z-index: 9999"
  >
    <v-card 
      class="pt-3 black"
    >
      <v-card-title>Atualize o aplicativo</v-card-title>
      <v-card-text class="py-4">
        <p class="body-1">
          Disponibilizamos uma nova versão do app Mobees para Motoristas!
        </p>
        <p class="body-1">
          Para garantir a melhor experiência, <b>instale a nova versão do app</b>.
        </p>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-btn
          text
          class="text--secondary"
          @click="show=false"
        >
          Depois
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="primary"
          class="accent--text"
          @click="openAppStore"
        >
          Atualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>

  export default {
    props: {
      toggle: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      show: false
    }),

    watch: {
      toggle: {
        immediate: true,
        handler (b) {
          this.show = Boolean(b);
        }
      },
      show (b) {
        if (!b) this.$emit('toggle', b);
      }
    },

    methods: {
      openAppStore () {
        window.location = 'https://play.google.com/store/apps/details?id=br.com.mobees.parceiro';
      },
    }
  }
</script>